<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <div class="card-header actions-toolbar border-0">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                        <h2 class="d-inline-block mb-0 card-title">辨識調校訓練</h2>
                                    </div>
                                </div>
                            </div>
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row >
                                <v-col class="px-10" align="center">
                                    <img :src="domain + '/HyirecoM/api/characteristic/showDetectRecordImg/' + $route.params.drId">
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field

                                        label="姓名"
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="px-10">
                                    <v-text-field
                                        v-model="persionId"
                                        label="編號"
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10" align="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="toTraining">
                                      訓練  
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col class="px-10" align="center">
                                    <v-radio-group v-model="persionId" column>
                                        <v-data-table
                                        :headers="headers"
                                        :items="datalist"
                                        disable-sort
                                        class="mx-12"> 
                                            <template v-slot:[`item.image`]={item}>
                                                <img :src="domain + '/HyirecoM/api/characteristic/showSimilarFaceImg/' + item.sfId" width="200px">
                                            </template>
                                            <template v-slot:[`item.selection`]={item}>
                                                <v-radio
                                                    :value="item.persionId"
                                                ></v-radio>
                                            </template>
                                        </v-data-table>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {moreLearning, deepLearning} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
  },
  destroyed() {
        clearInterval(this.interval);
  },
  mounted(){
    //console.log("loginAuth" + JSON.stringify(this.loginAuth));
    this.userName = this.loginAuth.memberName;
    this.domain = window.location.origin === "http://localhost:8081" ? "http://10.10.66.124" : window.location.origin;
    this.getDataList();
  },
  computed: {
    headers() {
      return [
          { text: '', value: 'selection', class: "subtitle-2 font-weight-black",width:80,},
          { text: '相似照片', value: 'image', class: "subtitle-2 font-weight-black",},
          { text: '辨識度', value: 'similarity', class: "subtitle-2 font-weight-black", width:80,},
          { text: '人員編號', value: 'persionId', class: "subtitle-2 font-weight-black",width:80, },
      ]
    },
  },
  methods: {
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        moreLearning(vm.$route.params.drId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("response = " + JSON.stringify(responseData));
                
                if (responseData.statusCode === "1") {
                    vm.datalist = responseData.detectRecord.faceList;
                    //console.log("datalist = " + JSON.stringify(vm.datalist));
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    toTraining: function() {
        var vm = this;
        vm.isLoading = true;
        
        var requestData = new Object();
        requestData.drId = vm.$route.params.drId;
        requestData.trainingOrgmCode = vm.persionId;

        deepLearning(requestData, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("response = " + JSON.stringify(response));
                vm.$swal(responseData.message);
                // if (responseData.statusCode === "1") {
                //     vm.datalist = responseData.detectRecord.faceList;
                // } else {
                //     vm.datalist = [];
                //     vm.$swal(responseData.message);
                // }
                vm.toCancel();
            }
            vm.isLoading = false;
        });
    },
    toCancel: function () {
      this.$router.push({ path: "/inoutRecord/inoutDetectRecordList" });
    },
  },
  watch: {

  },
  data: () => ({
    userName: "",
    datalist: [],
    pageSizeList: [],
    isLoading: false,
    auth:{},
    persionId: "",
    domain: "",
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
    
</style>
